import { useState } from "react";
import { IAmbient } from "../../store/ambient/types";
import { putScheduled } from "../../store/scheduled/actions";
import { IScheduled } from "../../store/scheduled/types";
import { store } from "../../store/store";
import MenuContext from "../atoms/MenuContext";
import classnames from "classnames";

const minTemp = 17;
const maxTemp = 23;
const temperatures = Array.from(Array(2 * (maxTemp - minTemp)).keys()).map(
  (v) => (v + minTemp * 2) / 2
);
export const TermoConfig = ({
  scheduleds,
  ambients,
}: {
  scheduleds: readonly IScheduled[];
  ambients: readonly IAmbient[];
}) => {
  const [enableAreaHour, setEnableAreaHour] = useState<number>(-1);
  const [enableTemperatureHour, setEnableTemperatureHour] = useState<number>(
    -1
  );
  let enableByHour: (area: number, temp: number) => any = function (
    area,
    temp
  ) {
    setEnableAreaHour(area);
    setEnableTemperatureHour(temp);
  };
  let changeSchedule: (
    hour: number,
    area: string,
    temperatura: number
  ) => any = function (hour, area, temperature) {
    store.dispatch(
      putScheduled({ hour: hour, area: area, temperature: temperature })
    );
    enableByHour(-1, -1);
  };
  return (
    <div className="w-full h-full     ">
      <div className="flex flex-wrap justify-center container mx-auto ">
        <table className="  divide-y divide-gray-200 ">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Ora
              </th>
              <th
                scope="col"
                className="px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Zona
              </th>
              <th
                scope="col"
                className="px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Corrente
              </th>
              <th
                scope="col"
                className="px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Temperatura
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-400">
            {scheduleds &&
              scheduleds.map((s) => (
                <tr key={"termo-id-" + s.hour}>
                  <td className="px-1 py-1 whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="text-sm font-medium text-gray-900">
                        {"dalle " +
                          (s.hour > 9 ? "" : "0") +
                          s.hour +
                          " alle " +
                          (s.hour + 1 > 9 ? "" : "0") +
                          (s.hour + 1)}
                      </div>
                    </div>
                  </td>
                  <td
                    key={"termo-menu-id-" + s.hour}
                    className="px-1 py-1 whitespace-nowrap"
                  >
                    <div
                      onClick={() => enableByHour(s.hour, -1)}
                      className="text-sm text-gray-900"
                    >
                      {s.area}
                    </div>
                    {
                      <MenuContext
                        keyInt={"area-" + s.hour}
                        hide={s.hour !== enableAreaHour}
                        onClick={(area) =>
                          changeSchedule(s.hour, area, s.temperature)
                        }
                        values={ambients.map((ambient) => ambient.name)}
                      ></MenuContext>
                    }
                  </td>
                  <td className="px-1 py-1 whitespace-nowrap">
                    <span
                      className={classnames(
                        "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800",
                        { hidden: true }
                      )}
                    >
                      Active
                    </span>
                  </td>
                  <td className="px-1 py-1 whitespace-nowrap text-sm text-gray-500">
                    <div
                      onClick={() => enableByHour(-1, s.hour)}
                      className="text-sm text-gray-900"
                    >
                      {s.temperature}
                    </div>
                    {
                      <MenuContext
                        keyInt={"temp-" + s.hour}
                        hide={s.hour !== enableTemperatureHour}
                        onClick={(temp) => changeSchedule(s.hour, s.area, temp)}
                        values={temperatures}
                      ></MenuContext>
                    }
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
