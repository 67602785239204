import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { setFancoil } from "../fancoil/actions";
import { IFancoil } from "../fancoil/types";
import {
  boostbusy,
  boostdone,
  boosterror,
  boilerSetState,
  setActive,
  enableFancoil,
} from "./actions";
import { IBoostState } from "./types";

export const reducer = (builder: ActionReducerMapBuilder<IBoostState>) => {
  builder.addCase(boostbusy, (state: IBoostState, action) => {
    state.status = "BUSY";
    return state;
  });
  builder.addCase(boostdone, (state: IBoostState, action) => {
    state.status = "DONE";
    return state;
  });
  builder.addCase(boosterror, (state: IBoostState, action) => {
    state.status = "ERROR";
    return state;
  });
  builder.addCase(boilerSetState, (state: IBoostState, action) => {
    state.boilerState = action.payload.do;
    return state;
  });
  builder.addCase(setActive, (state: IBoostState, action) => {
    return {
      ...state,
      inBoost: action.payload.boost,
      area: action.payload.area,
      temperature: action.payload.temperature,
    };
  });
  builder.addCase(setFancoil, (state: IBoostState, action) => {
    return {
      ...state,
      fancoilState: action.payload.value,
    };
  });
};
