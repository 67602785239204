import React, { useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import "./App.css";

import { SensorsData } from "./components/page/SensorsData"; 
import { TermoConfigData } from "./components/page/TermoConfigData";
import { Header } from "./components/template/Header";
import { IPage, PageState } from "./store/page/types";

function App() { 
  const pages: IPage = useSelector(
    (state: PageState) => state.pages,
    shallowEqual
  );
  return (
    <div className="bg-gray-200  ">
      <header className=" ">
        <div className=" ">
          <Header pages={pages} />
          { pages.current === "sensors" ? <SensorsData /> : <TermoConfigData /> }
        </div>
      </header>
    </div>
  ); 
}

export default App;
