import { AmbientState, IAmbient } from "../../store/ambient/types";
import { Sensors } from "../template/Sensors";
import { useSelector, shallowEqual } from "react-redux";
import { useEffect } from "react";
import { initStore, store } from "../../store/store";
import { IBoostState } from "../../store/boost/types";
let uuid = require('uuid')

export const SensorsData = () => {
  const ambient: readonly IAmbient[] = useSelector(
    (state: AmbientState) => state.ambients,
    shallowEqual
  );
  const boost: IBoostState = useSelector(
    (state: any) => state.boost,
    shallowEqual
  );

  useEffect(() => {
    store.dispatch(
      initStore({
        mqtt: {
          url: "192.168.200.17",
          clientId: "react-fe-" + uuid.v4(),
          port: 9001,
          client: undefined,
        },
      })
    );
  }, []);
  return <Sensors state={boost} ambients={ambient} />;
};
