import { Action } from "redux";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { censoSetResource } from "../censo/actions";
import { sendRollerClear, sendRollerClose, sendRollerHalfClose, sendRollerHalfOpen, sendRollerOpen } from "./actions";


export const epicSendRollerClear = (
    action$: Observable<Action>,
) =>
    action$.pipe(
        filter(sendRollerClear.match),
        map((action) => censoSetResource({ name: action.payload.resource, value: "clear" }))
    );

export const epicSendRollerOpen = (
    action$: Observable<Action>,
) =>
    action$.pipe(
        filter(sendRollerOpen.match),
        map((action) => censoSetResource({ name: action.payload.resource, value: "open" }))
    );
 
export const epicSendRollerClose = (
    action$: Observable<Action>,
) =>
    action$.pipe(
        filter(sendRollerClose.match),
        map((action) => censoSetResource({ name: action.payload.resource, value: "close" }))
    );
export const epicSendRollerHalfOpen = (
    action$: Observable<Action>,
) =>
    action$.pipe(
        filter(sendRollerHalfOpen.match),
        map((action) => censoSetResource({ name: action.payload.resource, value: "half-open" }))
    );

export const epicSendRollerHalfClose = (
    action$: Observable<Action>,
) =>
    action$.pipe(
        filter(sendRollerHalfClose.match),
        map((action) => censoSetResource({ name: action.payload.resource, value: "half-close" }))
    );
