import TextValue from "../atoms/TextValue";

export const Dew = (props: { where: string; value: number }) => {
  return (
    <>
      <TextValue
        title={"" + props.where}
        value={props.value}
        unit="°C"
        type="dew point"
      />
    </>
  );
};
