import { useState } from "react";
import { sendRollerClose, sendRollerOpen, sendRollerClear } from "../../store/roller/actions";
import { IRoller } from "../../store/roller/types";
import { store } from "../../store/store";
import UpDownCtrl from "../atoms/UpDownCtrl";

export const Roller = ({value: { resource, name} }: { value: IRoller }) => {
  const [actionType, setActionType] = useState<"open" | "close"| "clear" | undefined>();

  const sendEnvData = (e: React.FormEvent) => {
    e.preventDefault();
    if (actionType === "open") {
      store.dispatch(sendRollerOpen({resource}));
    } else if (actionType === "close") {
      store.dispatch(sendRollerClose({resource}));
    } else if (actionType === "clear") {
      store.dispatch(sendRollerClear({resource}));
    }
  };
  const formId = "roller-" + name + "-form"
  return (
    <>
      <form className="flex flex-col w-1/2 bg-gray-100 text-black rounded-lg pb-4 m-2" onSubmit={sendEnvData} id={formId}>
        <UpDownCtrl title={"Roller"} formId={formId} downSubmit={()=>setActionType("close")} centerSubmit={()=>setActionType("clear")} upSubmit={()=>setActionType("open")}  />
      </form>
    </>
  );
};
