
import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import { setPage } from './actions'
import { IPage } from './types'
 
//TODO:  Ma è giusto usare le action di un'altro slice? setHumidity e setTemperature?
export const reducer = (builder: ActionReducerMapBuilder<IPage>) => {
    builder.addCase(setPage, (state: IPage, action) => {
        state.current = action.payload.current
        return state
    })
}

