import { createSlice } from "@reduxjs/toolkit";
import { reducer } from "./reducers";
import { IBoostState } from "./types";

const initialState: IBoostState = {
  status: "DONE",
  boilerState: false,
  fancoilState: false,
  inBoost: false,
  area: "",
  temperature: 0.0,
};

export const slice = createSlice({
  name: "boost",
  initialState: initialState,
  reducers: {},
  extraReducers: reducer,
});
