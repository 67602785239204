import { useSelector, shallowEqual } from "react-redux";
import { IAmbient } from "../../store/ambient/types";
import { ScheduledState } from "../../store/scheduled/types";
import { TermoConfig } from "../template/TermoConfig";

export const TermoConfigData = () => {
  const termoconf: ScheduledState = useSelector(
    (state: any) => state.termoconf,
    shallowEqual
  );  
  const ambients: readonly IAmbient[] = useSelector(
    (state: any) => state.ambients,
    shallowEqual
  );
  return <TermoConfig ambients={ambients} scheduleds={termoconf.scheduling} />;
};
