
import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import { setTemperature } from '../temperature/actions'
import { setHumidity} from '../humidity/actions'
import { setAmbient} from './actions'
import { IAmbient } from './types'
 
//TODO:  Ma è giusto usare le action di un'altro slice? setHumidity e setTemperature?
export const reducer = (builder: ActionReducerMapBuilder<IAmbient[]>) => {
    builder.addCase(setHumidity, (state: IAmbient[], action) => {
        state.forEach(v => {
           if (v.name === action.payload.name) {
               v.humidity = action.payload.value
           }
        })
        return state
    })
    builder.addCase(setTemperature, (state: IAmbient[], action) => {
        state.forEach(v => {
           if (v.name === action.payload.name) {
               v.temperature = action.payload.value
           }
        })
        return state
    })
    builder.addCase(setAmbient, (state: IAmbient[], action) => {
        state.forEach(v => {
           if (v.name === action.payload.name) {
               v.temperature = action.payload.temperature
               v.humidity = action.payload.humidity
               v.dew = action.payload.dew
           }
           return v
        })
        return state
    })
    // builder.addCase(fetchStart, (state: IAmbient[], action) => {
    //     state.forEach(v => {
    //        //busy
    //        return v
    //     })
    //     return state
    // })
    // builder.addCase(fetchComplete, (state: IAmbient[], action) => {
    //     state.forEach(v => {
    //        //al massimo va a vedere se c'è un errore, altrimenti imposta i valori dello stato. e quindi reimposta lo stato da busy a done/error
    //        return v
    //     })
    //     return state
    // })
}

