import { createAction } from "@reduxjs/toolkit";
import { MQTTData, MQTTSubscribe } from "./types";

export const mqttSetClient = createAction<MQTTData>("mqtt/set/client");

export const mqttConnect = createAction("mqtt/connect");
export const mqttConnecting = createAction("mqtt/connecting");
export const mqttConnected = createAction("mqtt/connected");
export const mqttRetryConnect = createAction("mqtt/retry");
export const mqttSubscribe = createAction<MQTTSubscribe>("mqtt/subscribe");
