
import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import { allScheduled, setScheduled } from './actions'
import { ScheduledState } from './types'
 
export const reducer = (builder: ActionReducerMapBuilder<ScheduledState>) => {
    builder.addCase(allScheduled, (state: ScheduledState, action) => {
        return {
            ...state,
            ...action.payload,
            init: true,
        }
    })
}

