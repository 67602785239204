import React, { useState } from "react";
import _uniqueId from "lodash/uniqueId";
import TextValue from "../atoms/TextValue";

export const Temperature = (props: { where: string; value: number }) => {
  return (
    <>
      <TextValue
        title={"" + props.where}
        value={props.value}
        unit="°C"
        type="temperature"
      />
    </>
  );
};
