import { createSlice } from "@reduxjs/toolkit";
import { reducer } from "./reducers";
import { IPage } from "./types";

const initialState: IPage = {
    current: "sensors"  
};

// TODO: ma è meglio replicare l'informazione oppure fornire un link o le funzionalità di ricerca nello stato?
export const slice = createSlice({
  name: "pages",
  initialState: initialState,
  reducers: {},
  extraReducers: reducer,
});
