import { Action } from "@reduxjs/toolkit";
import { Observable } from "rxjs";
import { map, filter, withLatestFrom } from "rxjs/operators";
import { censoSetResource } from "../censo/actions";
import { boost, enableFancoil, unboost } from "./actions";

export const boostUnboostEpic = (action$: Observable<Action>, state$: Observable<any>) =>
  action$.pipe(
    filter((action) => boost.match(action) || unboost.match(action)), //TODO: verificare se si puo fare diversamente, tipo filter().filter()
    withLatestFrom(state$),
    filter(([, state]) => {
      return state.boost.state !== "BUSY"; //TODO: verificare se c'è un modo elegante per farlo
    }),
    map(([action, state]) => { 
      return censoSetResource({name: "termo-boost", value: action.type === boost.type ? "1" : "2"});
    })
  );

  export const fancoilDoEpic = (action$: Observable<Action>, state$: Observable<any>) =>
  action$.pipe(
    filter(enableFancoil.match),
    withLatestFrom(state$),
    filter(([, state]) => {
      return state.boost.state !== "BUSY";
    }),
    map(([action, state]) => { 
      return censoSetResource({name: "fancoil-switch", value: action.payload.do ? "on" : "off"});
    })
  );
