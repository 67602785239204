import classnames from "classnames";

function MenuContext({
  values,
  hide,
  keyInt,
  onClick,
}: {
  values: any[];
  hide: boolean;
  keyInt: string;
  onClick?: (val: any) => any;
}) {
  return (
    <div className={classnames("bg-white absolute block", { hidden: hide })}>
      <div className="w-40 border border-gray-300 rounded-lg flex flex-col text-sm px-2 text-gray-500 shadow-lg">
        {values.map((val) => (
          <div
            key={"contex-menu-" + val + "-" + keyInt}
            onClick={() => onClick!(val)}
            className="flex hover:bg-gray-100  px-2 rounded"
          >
            <div>{val}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default MenuContext;
