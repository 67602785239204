import { Action, ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { Observable, zip } from "rxjs";
import { map, filter, ignoreElements } from "rxjs/operators";
import { store } from "./store";

export function putAndDo(
  endpoint: string,
  successAction: ActionCreatorWithPayload<any, string>,
  body?: any,
  failAction?: ActionCreatorWithPayload<number, string>
) {
  fetch(endpoint, {
    method: "PUT",
    //TODO: Export function fetch to utility
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer test", // TODO: adding real authentication
    },
    body: body,
  }).then((response) => {
    if (response.status === 200) {
      store.dispatch(successAction(response));
    } else if (failAction) {
      store.dispatch(failAction!); //TODO: report error into IBoost
    }
  });
}
export function getAndDo(
  endpoint: string,
  successAction: ActionCreatorWithPayload<any, string>,
  failAction?: ActionCreatorWithPayload<number, string>
) {
  fetch(endpoint, {
    method: "GET",
    //TODO: Export function fetch to utility
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer test", // TODO: adding real authentication
    },
  }).then((response) => {
    console.log(response);
    if (response.status === 200) {
      response.json().then((data) => store.dispatch(successAction(data)));
    } else if (failAction) {
      store.dispatch(failAction(response.status)); //TODO: report error into IBoost
    }
  });
}
