import { Action } from "@reduxjs/toolkit";
import { Observable } from "rxjs";
import { map, filter, delay, ignoreElements, withLatestFrom } from "rxjs/operators";
import { setTemperature } from "../temperature/actions";
import { setHumidity } from "../humidity/actions";
import {
  mqttConnect,
  mqttConnecting,
  mqttConnected,
  mqttRetryConnect,
  mqttSubscribe,
  mqttSetClient,
} from "./actions";
import { store } from "../store";
import Paho from "paho-mqtt";
import { boilerSetState, setActive } from "../boost/actions";
import { allScheduled } from "../scheduled/actions";
import { setFancoil } from "../fancoil/actions";
import { setRollerValue } from "../roller/actions";

export const mqttSetClientEpic = (action$: Observable<Action>, state$: Observable<any>) =>
  action$.pipe(
    filter(mqttSetClient.match),
    withLatestFrom(state$),
    map(([action, state]) => {
      console.log(action.payload);
      return mqttConnect();
    })
  );

export const mqttConnectEpic = (action$: Observable<Action>, state$: Observable<any>) =>
  action$.pipe(
    filter(mqttConnect.match),
    withLatestFrom(state$),
    map(([action, state]) => {
      console.log(action.payload);
      return mqttConnecting();
    })
  );

export const mqttConnectingEpic = (action$: Observable<Action>, state$: Observable<any>) =>
  action$.pipe(
    filter(mqttConnecting.match),
    withLatestFrom(state$),
    map(([action, state]) => {
      state.mqtt.client.connect({
        onSuccess: () => {
          store.dispatch(mqttConnected());
        },
        onFailure: () => {
          store.dispatch(mqttRetryConnect());
        },
      });
    }),
    ignoreElements()
  );

export const mqttConnectedEpic = (action$: Observable<Action>, state$: Observable<any>) =>
  action$.pipe(
    filter(mqttConnected.match),
    withLatestFrom(state$),
    map(([, state]) => {
      return mqttSubscribe({
        topic: [
          "resource/fancoil-switch",
          "resource/termo-soggiorno-humidity",
          "resource/termo-soggiorno-temperature",
          "resource/termo-cameretta-humidity",
          "resource/termo-cameretta-temperature",
          "resource/termo-studio-humidity",
          "resource/termo-studio-temperature",
          "resource/termo-esterno-humidity",
          "resource/termo-esterno-temperature",
          "resource/termo-padronale-humidity",
          "resource/termo-padronale-temperature",
          "resource/termo-caldaia-status",
          "resource/termo-active/metadata",
          "resource/termo-config/metadata",
          "resource/roller-a",
          "resource/roller-b",
        ], //TODO: to be ported inside the state/config
        client: state.mqtt.client!,
        mqttProp: state.mqtt,
      });
    })
  );

const matchTopicTemperature = /resource\/termo-([a-z]+)-([a-z]+)/
export const mqttSubscribeEpic = (action$: Observable<Action>, state$: Observable<any>) =>
  action$.pipe(
    filter(mqttSubscribe.match),
    map((action) => {
      action.payload.client.onConnectionLost = (msg: any) => {
        return mqttConnect();
      };
      action.payload.client.onConnectionLost = (error: Paho.MQTTError) => {
        console.log(error)
        store.dispatch(mqttConnect())
      }
      action.payload.client.onMessageArrived = (msg: any) => {
        console.log("action: ", msg.destinationName, msg.payloadString, msg)
        if (msg.destinationName === "resource/termo-caldaia-status") {
          store.dispatch(
            boilerSetState({
              do: msg.payloadString === "1",
            })
          );
        } else if (msg.destinationName === "roller-a") {
          store.dispatch(setRollerValue({
            name: "cameretta",
            value: msg.payloadString,
          }))
        } else if (msg.destinationName === "roller-b") {
          store.dispatch(setRollerValue({
            name: "padronale",
            value: msg.payloadString,
          }))
        } else if (msg.destinationName === "resource/fancoil-switch") {
          store.dispatch(setFancoil({
            name: "soggiorno",
            value: msg.payloadString === "on",
          }))
        } else if (msg.destinationName.match(matchTopicTemperature)) {
          const match = msg.destinationName.match(matchTopicTemperature)
          let action = undefined
          if (match[2] === 'temperature') action = setTemperature({
            name: match[1],
            value: msg.payloadString,
          })
          else if (match[2] === 'humidity') action = setHumidity({
            name: match[1],
            value: msg.payloadString,
          })
          if (action) store.dispatch(action);
        } else if (msg.destinationName === "resource/termo-active/metadata") {
          console.log("===============================>", msg.payloadString)
          let payload = JSON.parse(msg.payloadString);
          store.dispatch(
            setActive({
              area: payload.area,
              boost: payload.boost,
              temperature: payload.temp,
            })
          );
        } else if (msg.destinationName === "resource/termo-config/metadata") {
          console.log("===============================>", msg.payloadString)
          let payload = JSON.parse(msg.payloadString);
          store.dispatch(
            allScheduled(payload)
          );
        }
      };
      action.payload.topic.forEach((t) =>
        action.payload.client.subscribe(t, {
          onSuccess: () => {
            console.log("onSuccess");
          },
          onFailure: () => {
            console.log("onFailure");
          },
        })
      );
    }),
    ignoreElements()
  );

export const mqttRetryConnectEpic = (
  action$: Observable<Action>,
  state$: any
) =>
  action$.pipe(
    filter(mqttRetryConnect.match),
    delay(3000),
    map((action) => {
      return mqttConnect();
    })
  );
