
import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import { setFancoil } from './actions'
import { IFancoil } from './types'

export const reducer = (builder: ActionReducerMapBuilder<IFancoil[]>) => {
    builder.addCase(setFancoil, (state: IFancoil[], action) => {
        state.forEach(v => {
           if (v.name === action.payload.name) {
               v.value = action.payload.value
           }
        })
    })
}

