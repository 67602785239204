
import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import { setHumidity } from './actions'
import { IHumidity } from './types'


export const reducer = (builder: ActionReducerMapBuilder<IHumidity[]>) => {
    builder.addCase(setHumidity, (state: IHumidity[], action) => {
        state.forEach(v => {
           if (v.name === action.payload.name) {
               v.value = action.payload.value
           }
        })
        return state
    })
}
