import { Action } from "@reduxjs/toolkit";
import { Observable, zip } from "rxjs";
import { map, filter, ignoreElements ,withLatestFrom} from "rxjs/operators";
import { censoSetMetadata } from "../censo/actions";

import { getAndDo, putAndDo } from "../rest";
import { store } from "../store";

import {
  setScheduled,
  allScheduled,
  initScheduled,
  putScheduled,
  putScheduledDone,
} from "./actions";
import { ScheduledState } from "./types";

// export const initScheduledEpic = (action$: Observable<Action>, state$: any) =>
//   action$.pipe(
//     filter(initScheduled.match),
//     map((action) => {
//       getAndDo(state$.value.boost.endpoint + "/scheduleds/get", allScheduled);
//     }),
//     ignoreElements()
//   );

// export const getAllScheduledEpic = (action$: Observable<Action>, state$: any) =>
//   action$.pipe(
//     filter(allScheduled.match),
//     map((action) => {
//         action.payload?.scheduling?.map((payload: any) => {
//         store.dispatch(
//           setScheduled({
//             hour: payload.hour,
//             area: payload.area,
//             temperature: payload.temperature,
//           })
//         );
//         return undefined;
//       });
//     }),
//     ignoreElements()
//   );

export const putScheduledEpic = (action$: Observable<Action>, state$: any) =>
  action$.pipe(
    filter(putScheduled.match),
    withLatestFrom(state$),
    map(([{ type, payload }, state]) => {
      const termoconf = (state as any).termoconf as ScheduledState
      let value = {
        areas: [...termoconf.areas],
        scheduling: [...termoconf.scheduling],
      }
      value.scheduling[payload.hour] = payload
      console.log("============>", payload, termoconf)
      return censoSetMetadata({
        name: "termo-config", 
        value: value,
      })
    }),
  );
