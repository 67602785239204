import { createSlice } from "@reduxjs/toolkit";
import { IAmbient } from "./types";
import { reducer } from "./reducers";

const initialState: IAmbient[] = [
  { name: "soggiorno", temperature: 0.0, humidity: 0.0, dew: 0.0, fancoil: false},
  { name: "studio", temperature: 0.0, humidity: 0.0, dew: 0.0 },
  { name: "padronale", temperature: 0.0, humidity: 0.0, dew: 0.0 },
  { name: "cameretta", temperature: 0.0, humidity: 0.0, dew: 0.0 },
  { name: "esterno", temperature: 0.0, humidity: 0.0, dew: 0.0 },
];

// TODO: ma è meglio replicare l'informazione oppure fornire un link o le funzionalità di ricerca nello stato?
export const slice = createSlice({
  name: "ambients",
  initialState: initialState,
  reducers: {},
  extraReducers: reducer,
});
