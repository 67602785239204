import { createSlice } from "@reduxjs/toolkit";
import { reducer } from "./reducers";
import { IFancoil } from "./types";

const initialState: IFancoil[] = [
  { name: "soggiorno", value: false},
];

export const slice = createSlice({
  name: "fancoil",
  initialState: initialState,
  reducers: {},
  extraReducers: reducer,
});
