import { IAmbient } from "../../store/ambient/types";
import { IBoostState } from "../../store/boost/types";
import { AmbientSensor } from "../organism/AmbientSensor";

import { BoostTermo } from "../organism/BoostTermo";

export const Sensors = ({
  ambients,
  state,
}: {
  ambients: readonly IAmbient[];
  state: IBoostState;
}) => {
  return (
    <div className=" w-full h-full   ">
      <div className="flex flex-wrap justify-center container mx-auto ">
        <div className="m-5 w-full grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-5">
          {ambients.map((v) => (
            <AmbientSensor
              key={v.name}
              valueTemperature={v.temperature}
              valueHumidity={v.humidity}
              valueDew={v.dew}
              where={v.name}
              state={state}
            ></AmbientSensor>
          ))}
          <BoostTermo state={state} where="Controls" />
        </div>
      </div>
    </div>
  );
};
