import { Temperature } from "../molecules/Temperature";
import { Humidity } from "../molecules/Humidity";
import { Dew } from "../molecules/Dew";
import classnames from "classnames";
import { IBoostState } from "../../store/boost/types";
import { Roller } from "../molecules/Roller";
import { useSelector } from "react-redux";
import { selectRoller } from "../../store/roller/selectors";

const Blank = <div className="flex flex-col w-1/2 rounded-lg pb-4 m-2"></div>;
export const AmbientSensor = ({
  valueTemperature,
  valueHumidity,
  valueDew,
  where,
  state,
}: {
  valueTemperature: number;
  valueHumidity: number;
  valueDew: number;
  where: string;
  state: IBoostState;
}) => {
  const roller = useSelector(selectRoller)[where]
  const checkActiveArea = state.area.toLowerCase() === where;
  return (
    <>
      <div className="  rounded-xl bg-gray-800 text-white p-8  w-full">
        <h3
          className={classnames("mb-3 font-bold uppercase ", {
            "text-red-500": checkActiveArea,
          })}
        >
          {where} {checkActiveArea && " (" + state.temperature + "°C)"}
        </h3>
        <div className=" justify-between flex flex-row mb-2">
          <Temperature value={valueTemperature} where="" />
          <Humidity value={valueHumidity} where="" />
        </div>
        <div className=" justify-between flex flex-row">
          <Dew value={valueDew} where="" />
          {roller ? <Roller value={roller}/> : Blank}
        </div>
      </div>
    </>
  );
};
