import {
  BOOST,
  BoostButton,
  FANCOIL,
  FIRE,
  OFF,
  UNBOOST,
} from "../atoms/BoostButton";

import React, { useState } from "react";
import { store } from "../../store/store";
import { boost, enableFancoil, unboost } from "../../store/boost/actions";
import { IBoostState } from "../../store/boost/types";

export const BoostTermo = ({
  where,
  state,
}: {
  where: string;
  state: IBoostState;
}) => {
  return (
    <>
      <div className="  rounded-xl bg-gray-800 text-white p-8  w-full">
        <h3 className="mb-3 font-bold uppercase">
          {where}
        </h3>
        <div className=" justify-between flex flex-row mb-2">
          <BoostButton
            type={BOOST}
            onSubmit={() => boost({ do: true })}
            state={state}
            formId="boost-form"
          />
          <BoostButton
            type={UNBOOST}
            onSubmit={() => unboost({ do: true })}
            formId="unboost-form"
            state={state}
          />
        </div>
        <div className=" justify-between flex flex-row">
          <BoostButton
            type={state.boilerState ? FIRE : OFF}
            state={state}
          />
          <BoostButton
            type={state.fancoilState ? FANCOIL : OFF}
            onSubmit={() => enableFancoil({ do: !state.fancoilState })}
            formId="fancoil-form"
            state={state}
          />
        </div>
      </div>
    </>
  );
};
