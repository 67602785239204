import { Action } from "@reduxjs/toolkit";
import { Observable, zip } from "rxjs";
import { map, filter, ignoreElements, withLatestFrom } from "rxjs/operators";
import { setTemperature } from "../temperature/actions";
import { setHumidity } from "../humidity/actions";
import { initAmbient, setAmbient, allAmbient } from "./actions";
import { IAmbient } from "./types";
import { getAndDo } from "../rest";
import { store } from "../store";
import { setFancoil } from "../fancoil/actions";

// TODO: Ma la logica dove va? In questo caso il calcolo del punto di rugiada è corretto metterlo nell'epic?
function calculateDew(humidity: number, temperature: number) {
  let a = 17.27;
  let b = 237.7;
  let UR = humidity / 100;
  let alpha = (a * temperature) / (b + temperature) + Math.log(UR);
  let dew = (b * alpha) / (a - alpha);
  dew = Math.round(100 * dew) / 100;
  return dew;
}

// TODO: In questo caso si è reso necessario fare due epic, ma forse si potevano unificare con la | sui tipi?
export const tempToDewpointEpic = (action$: Observable<Action>, state$: Observable<any>) =>
  action$.pipe(
    filter(setTemperature.match),
    withLatestFrom(state$),
    filter(([action, state]) => {
      let U = state.ambients?.find(
        (v: IAmbient) => v.name === action.payload.name
      )?.humidity;
      return U && U > 0;
    }),
    map(([action, state]) => {
      // humidity is warantee by previous filter
      let ambient = state.ambients?.find(
        (v: IAmbient) => v.name === action.payload.name
      ) as IAmbient;
      let dew = 0
      let value : number = Number(action.payload.value)
      dew = calculateDew(ambient.humidity, value);
      return setAmbient({...ambient, dew: dew, temperature: value});
    })
  );

export const humToDewpointEpic = (action$: Observable<Action>, state$: Observable<any>) =>
  action$.pipe(
    filter(setHumidity.match),
    withLatestFrom(state$),
    filter(([action, state]) => {
      let U = state.ambients?.find(
        (v: IAmbient) => v.name === action.payload.name
      )?.temperature;
      return U && U > 0;
    }),
    map(([action,state]) => {
      // temperature is warantee by previous
      let ambient = state.ambients?.find(
        (v: IAmbient) => v.name === action.payload.name
      ) as IAmbient;
      let dew = 0
      let value : number = Number(action.payload.value)
      dew = calculateDew(value, ambient.temperature);
      return setAmbient({...ambient, dew: dew, humidity: value});
    })
  );

export const fancoilEpic = (action$: Observable<Action>, state$: Observable<any>) =>
  action$.pipe(
    filter(setFancoil.match),
    withLatestFrom(state$),
    map(([action, state]) => {
      let ambient = state.ambients?.find(
        (v: IAmbient) => v.name === action.payload.name
      );
      return setAmbient({
        ...ambient,
        fancoil: action.payload.value,
      });
    })
  );


export const initAmbientEpic = (action$: Observable<Action>, state$: Observable<any>) =>
  action$.pipe(
    filter(initAmbient.match),
    withLatestFrom(state$),
    map(([action, state]) => {
      console.log("initAmbientEpic",state)
      getAndDo(state.boost.endpoint + "/ambients/get", allAmbient);
    }),
    ignoreElements()
  );

export const getAllAmbientEpic = (action$: Observable<Action>, state$: Observable<any>) =>
  action$.pipe(
    filter(allAmbient.match),
    map((action) => {
      action.payload.ambients.map((payload: any) => {
        if (payload.sensorType === "temperature") {
          store.dispatch(
            setTemperature({
              name: payload.area.toLowerCase(),
              value: payload.value,
            })
          );
        } else if (payload.sensorType === "humidity") {
          store.dispatch(
            setHumidity({
              name: payload.area.toLowerCase(),
              value: payload.value,
            })
          );
        }
        return undefined;
      });
    }),
    ignoreElements()
  );
