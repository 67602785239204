import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { MQTTData } from "./types";
import { mqttSetClient } from "./actions";
import Paho from "paho-mqtt";

export const reducer = (builder: ActionReducerMapBuilder<MQTTData>) => {
  builder.addCase(mqttSetClient, (state: MQTTData, action) => {
    action.payload.client = new Paho.Client(
      action.payload.url,
      Number(action.payload.port),
      action.payload.clientId
    );
    return {
        ...state,
        ...action.payload
    };
  });
};
