import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { censoSetDone, censoSetFail, censoSetResource } from "./actions";
import { CensoState } from "./types";

export const reducer = (builder: ActionReducerMapBuilder<CensoState>) => {
  builder.addCase(censoSetResource, (state: CensoState, action) => {
    return {
      ...state,
      type: censoSetResource.name,
      status: "BUSY",
      action: action.payload,
    };
  });
  builder.addCase(censoSetDone, (state: CensoState, action) => {
    return {
      ...state,
      status: "DONE",
    };
  });
  builder.addCase(censoSetFail, (state: CensoState, action) => {
    return {
      ...state,
      status: "FAIL",
      response: action.payload,
    };
  });
};
