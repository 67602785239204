import { createSlice } from "@reduxjs/toolkit";
import { reducer } from "./reducers";
import { IScheduled, ScheduledState } from "./types";

const initialState: ScheduledState = {
  init: false,
  areas: [],
  scheduling: [
  { hour:  0, area: "", temperature: 0 },
  { hour:  1, area: "", temperature: 0 },
  { hour:  2, area: "", temperature: 0 },
  { hour:  3, area: "", temperature: 0 },
  { hour:  4, area: "", temperature: 0 },
  { hour:  5, area: "", temperature: 0 },
  { hour:  6, area: "", temperature: 0 },
  { hour:  7, area: "", temperature: 0 },
  { hour:  8, area: "", temperature: 0 },
  { hour:  9, area: "", temperature: 0 },
  { hour: 10, area: "", temperature: 0 },
  { hour: 11, area: "", temperature: 0 },
  { hour: 12, area: "", temperature: 0 },
  { hour: 13, area: "", temperature: 0 },
  { hour: 14, area: "", temperature: 0 },
  { hour: 15, area: "", temperature: 0 },
  { hour: 16, area: "", temperature: 0 },
  { hour: 17, area: "", temperature: 0 },
  { hour: 18, area: "", temperature: 0 },
  { hour: 19, area: "", temperature: 0 },
  { hour: 20, area: "", temperature: 0 },
  { hour: 21, area: "", temperature: 0 },
  { hour: 22, area: "", temperature: 0 },
  { hour: 23, area: "", temperature: 0 },
]};

// TODO: ma è meglio replicare l'informazione oppure fornire un link o le funzionalità di ricerca nello stato?
export const slice = createSlice({
  name: "termoconf",
  initialState: initialState,
  reducers: {},
  extraReducers: reducer,
});
