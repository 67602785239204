import { createSlice } from "@reduxjs/toolkit";
import { reducer } from "./reducers";
import { ITemperature } from "./types";

const initialState: ITemperature[] = [
  { name: "soggiorno", value: 0.0 },
  { name: "studio", value: 0.0 },
  { name: "padronale", value: 0.0 },
  { name: "cameretta", value: 0.0 },
  { name: "esterno", value: 0.0 },
];

export const slice = createSlice({
  name: "temperatures",
  initialState: initialState,
  reducers: {},
  extraReducers: reducer,
});
