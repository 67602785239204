import { createSlice } from "@reduxjs/toolkit";
import { reducer } from "./reducers";
import { IRoller } from "./types";

const initialState: IRoller[] = [
  { name: "cameretta", resource: "roller-a", value: "", status: "BUSY"},
  { name: "padronale", resource: "roller-b", value: "", status: "BUSY"},
];

// TODO: ma è meglio replicare l'informazione oppure fornire un link o le funzionalità di ricerca nello stato?
export const slice = createSlice({
  name: "rollers",
  initialState: initialState,
  reducers: {},
  extraReducers: reducer,
});
