import TermoLogo from "../../../src/assets/high-temperature.png";
import HumidityLogo from "../../../src/assets/humidity.png";
import DewLogo from "../../../src/assets/wet.png";
import FireLogo from "../../../src/assets/fire.png";

const TEMP = "temperature";
const HUMI = "humidity";
const DEWP = "dew point";
const FIRE = "fire";

const logoMap = {
  [TEMP]: TermoLogo,
  [HUMI]: HumidityLogo,
  [DEWP]: DewLogo,
  [FIRE]: FireLogo,
};
function TextValue({
  title,
  value,
  unit,
  type,
}: {
  //TODO: trovare un nome piu adatto
  title: string;
  value?: number;
  unit: string;
  type: typeof TEMP | typeof HUMI | typeof DEWP | typeof FIRE;
}) {
  const logo = logoMap[type];
  return (
    <div className="flex flex-col w-1/2 bg-gray-100 text-black rounded-lg pb-4 m-2">
      <div className="m-2 ">
        <div className="text-center pt-2 mb-2">
          <img className="w-10 h-10 mx-auto" src={logo} alt={type} />
        </div>
        <div className="text-center h-15">
          <b className="font-normal">{type}</b>
          <br />
          <strong className="text-xl">
            {value && Math.round(value * 100) / 100} {unit}
          </strong>
        </div>
      </div>
    </div>
  );
}

export default TextValue;
