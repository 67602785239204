
import { ActionReducerMapBuilder } from '@reduxjs/toolkit'
import { setRollerValue } from './actions'
import { IRoller, IRollerValue } from './types'
 
export const reducer = (builder: ActionReducerMapBuilder<IRoller[]>) => {
    builder.addCase(setRollerValue, (state: IRoller[], action : { type: string, payload: IRollerValue}) => {
        state.forEach(v => { 
           if (v.name === action.payload.name) {
               v.value = action.payload.value
           }
        })
        return state
    })
}

