import { createSlice } from "@reduxjs/toolkit";
import { reducer } from "./reducers";
import { CensoState } from "./types";

const initialState: CensoState = {
  type: "NONE",
  endpoint: "http://192.168.200.10:40000/api",
  action: {},
  status: "DONE",
};

export const slice = createSlice({
  name: "censo",
  initialState: initialState,
  reducers: {},
  extraReducers: reducer,
});
