import { createSlice } from "@reduxjs/toolkit";
import { reducer } from "./reducers";
import { MQTTData } from "./types";

const initialState: MQTTData = {
  client: undefined,
  url: "",
  port: 0,
  clientId: ""
};

export const slice = createSlice({
  name: "mqtt",
  initialState: initialState,
  reducers: {},
  extraReducers: reducer,
});
