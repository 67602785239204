import Up from "../../../src/assets/up.svg";
import Down from "../../../src/assets/down.svg";
import Window from "../../../src/assets/window.svg";
import { FormEventHandler } from "react";

function UpDownCtrl({
  title,
  value = "",
  formId,
  upSubmit,
  centerSubmit,
  downSubmit,
}: {
  title: string;
  value?: string;
  formId: string;
  upSubmit: FormEventHandler<HTMLButtonElement>;
  centerSubmit: FormEventHandler<HTMLButtonElement>;
  downSubmit: FormEventHandler<HTMLButtonElement>;
}) {
  return (
    <div className="m-2 ">
      <div className="text-center pt-2 mb-2">
        <button
          className="focus:outline-none"
          type="submit"
          onClick={upSubmit}
          form={formId}
        >
          <img className="w-10 h-5 mx-auto" src={Up} alt="up" />
        </button>
      </div>
      <div className="text-center">
        <button
          className="focus:outline-none"
          type="submit"
          onClick={centerSubmit}
          form={formId}
        >
          <img className="w-10 h-5 mx-auto" src={Window} alt="stop" />
        </button>
      </div>
      <div className="text-center pt-2 mb-2">
        <button
          className="focus:outline-none"
          type="submit"
          onClick={downSubmit}
          form={formId}
        >
          <img className="w-10 h-5 mx-auto" src={Down} alt="down" />
        </button >
      </div>
    </div>
  );
}

export default UpDownCtrl;
