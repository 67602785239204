import { createAction } from "@reduxjs/toolkit";
import { IActiveState, IBoost } from "./types";

export const boost = createAction<IBoost>("boost/do");
export const unboost = createAction<IBoost>("boost/undo");

export const boostbusy = createAction<IBoost>("boost/busy"); // TODO: boostbusy gestirlo con redux invece di due stati
export const boostdone = createAction<any>("boost/done");
export const boosterror = createAction<any>("boost/error");
export const boilerSetState = createAction<IBoost>("boiler/set");
export const setActive = createAction<IActiveState>("active/set");

export const enableFancoil = createAction<IBoost>("fancoil/set");
