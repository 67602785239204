import TextValue from "../atoms/TextValue";

export const Humidity = (props: { where: string; value: number }) => {
  return (
    <>
      <TextValue
        title={"" + props.where}
        value={props.value}
        unit="%"
        type="humidity"
      />
    </>
  );
};
