import { createSlice } from '@reduxjs/toolkit'
import { reducer } from './reducers'
import { IHumidity } from './types'

const initialState: IHumidity[] = [
  { name: "soggiorno", value: 0.0 },
  { name: "studio", value: 0.0 },
  { name: "padronale", value: 0.0 },
  { name: "cameretta", value: 0.0 },
  { name: "esterno", value: 0.0 },
]

export const slice = createSlice({
  name: 'humidities',
  initialState: initialState,
  reducers: {},
  extraReducers: reducer
})
 