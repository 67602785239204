import { FormEventHandler, useState } from "react";

import BoostLogo from "../../../src/assets/on.svg";
import StopLogo from "../../../src/assets/off.svg";
import FireLogo from "../../../src/assets/fire.png";
import NoLogo from "../../../src/assets/no-logo.png";
import Fancoil from "../../../src/assets/fan.svg";
import classnames from "classnames";
import { IBoost, IBoostState } from "../../store/boost/types";
import { store } from "../../store/store";

export const BOOST = "Switch ON";
export const UNBOOST = "Switch OFF";
export const FIRE = "Fire On";
export const FANCOIL = "Fancoil On";
export const OFF = "Off";

const logoMap = {
  [BOOST]: { logo: BoostLogo, overlay: undefined, label: "Boost" },
  [UNBOOST]: { logo: StopLogo, overlay: undefined, label: "Unboost" },
  [FIRE]: { logo: FireLogo, overlay: undefined, label: "On" },
  [FANCOIL]: { logo: Fancoil, overlay: undefined, label: "On" },
  [OFF]: { logo: NoLogo, overlay: undefined, label: "Off" },
};

export function BoostButton({
  type,
  formId,
  onSubmit,
  state,
}: {
  type: typeof BOOST | typeof UNBOOST | typeof FIRE | typeof OFF | typeof FANCOIL;
  formId?: string;
  onSubmit?: () => { payload: IBoost; type: string; };
  state: IBoostState;
}) {
  let test = type === FIRE;
  if (type === BOOST && state!.inBoost) {
    test = true;
  }
  const logo = logoMap[type];
  const [, setActionType] = useState<"action" | undefined>();

  const sendEnvData = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit && store.dispatch(onSubmit());
  };
  return (
    <form className={classnames("flex flex-col w-1/2 bg-gray-100 text-black rounded-lg pb-4 m-2", { "bg-red-800": test })} onSubmit={sendEnvData} id={formId}>
      <button className="focus:outline-none"
        type="submit"
        onClick={() => setActionType("action")}
        form={formId}
      >
        <div className="m-2 ">
          <div className="text-center pt-2 mb-2">
            <img className=" w-10 h-10 mx-auto" src={logo.logo} alt={logo.label} />
          </div>
          <div className="text-center h-15">
            <b className="font-normal">{logo.label}</b>
            <br />
            <strong className="text-xl">-</strong>
          </div>
        </div>
      </button >
    </form>
  );
}
