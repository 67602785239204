import { Action, ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { Observable } from "rxjs";
import { map, filter, withLatestFrom } from "rxjs/operators";
import { ignoreElements } from "rxjs/operators";
import { store } from "../store";
import { censoSetDone, censoSetFail, censoSetResource,  censoSetMetadata} from "./actions";


function resourceSet(
  endpoint: string,
  resource: string,
  value: string,
  successAction: ActionCreatorWithPayload<any, string>,
  failAction?: ActionCreatorWithPayload<any, string>
) {
  fetch(endpoint + '/resource/' + resource, {
    method: "PUT",
    //TODO: Export function fetch to utility
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer root", // TODO: adding real authentication
    },
    body: value,
  }).then((response) => {
    if (response.status === 200) {
      store.dispatch(successAction(response));
    } else if (failAction) {
      store.dispatch(failAction(response)); //TODO: report error into IBoost
    }
  });
}

function metadataSet(
  endpoint: string,
  resource: string,
  value: any,
  successAction: ActionCreatorWithPayload<any, string>,
  failAction?: ActionCreatorWithPayload<any, string>
) {
  fetch(endpoint + '/resource/' + resource + '/metadata', {
    method: "PUT",
    //TODO: Export function fetch to utility
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer root", // TODO: adding real authentication
    },
    body: value,
  }).then((response) => {
    if (response.status === 200) {
      store.dispatch(successAction(response));
    } else if (failAction) {
      store.dispatch(failAction(response)); //TODO: report error into IBoost
    }
  });
}

export const censoSetResourceEpic = (action$: Observable<Action>, state$: Observable<any>) =>
  action$.pipe(
    filter(censoSetResource.match),
    withLatestFrom(state$),
    map(([action, state]) => {
      console.log(action.payload);
      resourceSet(state.censo.endpoint, action.payload.name, action.payload.value, censoSetDone, censoSetFail)
    }),
    ignoreElements()
  );

export const censoSetMetadataEpic = (action$: Observable<Action>, state$: Observable<any>) =>
  action$.pipe(
    filter(censoSetMetadata.match),
    withLatestFrom(state$),
    map(([action, state]) => {
      console.log(action.payload);
      metadataSet(state.censo.endpoint, action.payload.name, JSON.stringify(action.payload.value), censoSetDone, censoSetFail)
    }),
    ignoreElements()
  );

