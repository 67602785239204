import {
  createStore,
  combineReducers,
  applyMiddleware,
  createAction,
} from "@reduxjs/toolkit";
import { Action, compose } from "redux";
import { createEpicMiddleware, combineEpics } from "redux-observable";

import { slice as temperature } from "./temperature/store";
import { slice as humidity } from "./humidity/store";
import { slice as ambient } from "./ambient/store";
import { slice as boost } from "./boost/store";
import { slice as scheduled } from "./scheduled/store";
import { slice as page } from "./page/store";
import { slice as censo } from "./censo/store";
import { slice as fancoil } from "./fancoil/store";
import { slice as mqtt } from "./mqtt/store";
import { slice as roller } from "./roller/store";

import {
  tempToDewpointEpic,
  humToDewpointEpic,
  getAllAmbientEpic,
  initAmbientEpic,
  fancoilEpic,
} from "./ambient/epics";

import {
  mqttConnectedEpic,
  mqttConnectEpic,
  mqttConnectingEpic,
  mqttRetryConnectEpic,
  mqttSetClientEpic,
  mqttSubscribeEpic,
} from "./mqtt/epics";
import { boostUnboostEpic, fancoilDoEpic } from "./boost/epics";
import { filter, ignoreElements, map } from "rxjs/operators";
import { initAmbient } from "./ambient/actions";
import { initScheduled } from "./scheduled/actions";
import { mqttConnect, mqttConnecting, mqttSetClient } from "./mqtt/actions";
import { Observable } from "rxjs";
import {
//   getAllScheduledEpic,
//   initScheduledEpic,
  putScheduledEpic,
} from "./scheduled/epics";
import { censoSetMetadataEpic, censoSetResourceEpic } from "./censo/epics";
import { epicSendRollerClear, epicSendRollerClose, epicSendRollerHalfClose, epicSendRollerHalfOpen, epicSendRollerOpen } from "./roller/epics";

export const initStore = createAction<any>("init");

export const initEpic = (action$: Observable<Action>, state$: any) =>
  action$.pipe(
    filter(initStore.match),
    map((action) => {
      store.dispatch(initAmbient({}));
      store.dispatch(mqttSetClient(action.payload.mqtt));
      store.dispatch(initScheduled({}));
    }),
    ignoreElements()
  );

const reducers = combineReducers({
  temperatures: temperature.reducer,
  humidities: humidity.reducer,
  fancoil: fancoil.reducer,
  ambients: ambient.reducer,
  boost: boost.reducer,
  termoconf: scheduled.reducer,
  pages: page.reducer,
  censo: censo.reducer,
  mqtt: mqtt.reducer,
  rollers: roller.reducer,
});

const rootEpic = combineEpics(
  tempToDewpointEpic,
  humToDewpointEpic,
  mqttSetClientEpic,
  mqttConnectEpic,
  mqttConnectingEpic,
  mqttConnectedEpic,
  mqttSubscribeEpic,
  mqttRetryConnectEpic,
  censoSetResourceEpic,
  censoSetMetadataEpic,
  boostUnboostEpic,
  fancoilDoEpic,
  initAmbientEpic,
  getAllAmbientEpic,
  fancoilEpic,
//   initScheduledEpic,
//   getAllScheduledEpic,
  putScheduledEpic,
  initEpic,

  // roller
  epicSendRollerClear,
  epicSendRollerOpen,
  epicSendRollerClose,
  epicSendRollerHalfOpen,
  epicSendRollerHalfClose,
);

// import { ArticleState, ArticleAction, ArticleDispatch } from "./types/article";
// dev tool for browser integration
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const epicMiddleware = createEpicMiddleware();

export const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(epicMiddleware))
);
epicMiddleware.run(rootEpic);
