import { setPage } from "../../store/page/actions";
import { IPage } from "../../store/page/types";
import { store } from "../../store/store";

export const Header = ({ pages }: { pages: IPage }) => {
  return (
    <div className="lg:flex lg:items-center lg:justify-between">
      <div className="   ">
        <button
          onClick={() =>
            store.dispatch(
              setPage({
                current: pages.current === "sensors" ? "scheduler" : "sensors",
              })
            )
          }
          type="button"
          className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <svg
            className="-ml-1 mr-2 h-5 w-5 text-gray-500"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
          </svg>
          {pages.current === "sensors" ? "Schedule Termo" : "Show sensors"}
        </button>
      </div>
    </div>
  );
};
